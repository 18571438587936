<div class="container-fluid">
  <div class="row mt-5">
    <div class="offset-1 col">
      <h1>
        List of Test branches
      </h1>
    </div>
    <div class="offset-1 col-10 my-5">
      <form (ngSubmit)="getBranches()" novalidate>
        <div class="form-group">
          <label for="personal_access_token">Personal Access Token</label>
          <input type="email" class="form-control" id="personal_access_token" aria-describedby="access_token_help" [(ngModel)]="accessToken" name="access_token">
          <small id="access_token_help" class="form-text text-muted">
            Setup Personal Access token on Github <a href="https://github.com/settings/tokens">link</a>
          </small>
        </div>
        <button type="reset" (click)="clearStorage()" class="btn btn-secondary mr-3">Clear storage</button>
        <button class="btn btn-primary">Submit</button>

      </form>
    </div>
    <div class="offset-1 col-10">
      <h1>Frontend Repo</h1>
      <table class="table">
        <thead class="thead-dark">
        <tr>
          <th>Branch Name</th>
          <th>Message</th>
          <th>Author</th>
          <th>Date</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let branch of branches">
          <td>
            {{branch.branch_name}}
          </td>
          <td>
            {{branch.last_commit}}
          </td>
          <td>
            {{branch.author}}
          </td>
          <td>
            {{branch.date | date:'medium'}}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="row justify-content-center" *ngIf="loading">
        <div class="col-auto">
          <fa-icon [icon]="spinnerIcon" spin="true" size="6x"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
