import {Component, Inject} from '@angular/core';
import {Octokit} from '@octokit/rest';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

interface BranchInterface {
  branch_name: string;
  date: string;
  author: string;
  last_commit: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  branches: BranchInterface[] = [];
  accessToken: string;
  loading = false;
  spinnerIcon = faSpinner;
  private readonly accessTokenKey = '';

  constructor(
    @Inject(LOCAL_STORAGE) private readonly storageService: StorageService
  ) {
    this.accessToken = this.storageService.get(this.accessTokenKey);
    if (this.accessToken) {
      this.getBranches();
    }
  }

  clearStorage(): void {
    this.storageService.remove(this.accessTokenKey);
  }

  async getBranches(): Promise<void> {
    try {
      this.storageService.set(this.accessTokenKey, this.accessToken);
      this.loading = true;
      this.branches = [];
      const octokit = new Octokit({auth: this.accessToken});
      const result: BranchInterface[] = [];
      const queue = [];
      const branchNames: string[] = [
        'test',
        'test2',
        'test3',
        'test4',
        'test5',
      ];
      for (const branchName of branchNames) {
        queue.push(octokit.request(`/repos/Monsido/frontend/branches/${branchName}`).then(r => r.data));
      }
      const branches = await Promise.all(queue);
      for (const branch of branches) {
        result.push({
          branch_name: branch.name,
          date: branch.commit.commit.committer.date,
          author: branch.commit.commit.author.name,
          last_commit: branch.commit.commit.message
        });
      }
      this.branches = result;
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }


}
